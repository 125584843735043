import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Paper,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import { useForm } from "react-hook-form";
import logo from "../../assets/images/logo.png";
import whiteLogo from "../../assets/images/mobilelogo.png";
// import forgotPass from "../../assets/images/forgotPass.png";
import forgotPass from "../../assets/images/forgotPassword.gif";
import loginImage from "../../assets/images/loginback.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import $ from "jquery";
// import ClearIcon from '@mui/icons-material/Clear';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import SendIcon from '@mui/icons-material/Send';

const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
}));

const body = document.querySelector("body");
body.style.setProperty("--screen-height", $(window).height() - 100 + "px");
body.style.setProperty("--login-screen-height", $(window).height() + "px");

const ForgotPassword = () => {
  /*eslint eqeqeq: "off"*/

  let history = useHistory();
  const { REACT_APP_API_URL } = process.env;

  const [loading, setLoading] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState("");
  const [, setEmailError] = React.useState(false);

  
  const [title, setTitle] = useState("Elroi Consumer");

  useEffect(() => {
    document.title = title;
    setTitle("Elroi Consumer || Forgot Password")
  }, [title]);

  const {
    register,
    // formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const emailVerify = (e) => {
    // console.log(e);
    // alert(e)
    setUserInfo(e);
  };
  // console.log(userInfo);

  const onSubmit = (data) => {
    setTimeout(() => {
      setLoading(true);
    }, 500);
    setInterval(() => {
      setLoading(false);
    }, 3500)
    // debugger
    let obj = { forget_user: userInfo };
    // console.log(obj);
    axios
      .post(`${REACT_APP_API_URL}/forgot-password`, obj)
      .then((result) => {
        if (result.status === 200) {
          // console.log(result);
          setEmailError(false);
          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            // title: "",
            html: "Verification Code has been sent to your Email Successfully",
            timer: 2000,
            timerProgressBar: true,
          });
          
          setTimeout(function () {
            localStorage.setItem(
              "user",
              window.btoa(
                JSON.stringify({
                  user: userInfo,
                  isAuthenticated: true,
                  date: new Date(),
                })
              )
            );
            let userToken = localStorage.getItem("user");
            // console.log(localStorage.getItem("user"));
            // console.log(userToken);
            // let userIdDecode = window.atob(userToken);
            // console.log(userIdDecode);
            window.location.href = `/ResetPassword/${userToken}`;
          }, 3000);
        } else {
          // console.log(result);
          setEmailError(true);
          Swal.fire({
            icon: "warning",
            showConfirmButton: true,
            title: "User Email is In-Correct",
            // html: "",
            timer: 5000,
            timerProgressBar: true,
          });
          setTimeout(function () {
            history.replace("/login");
          }, 3000);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log(error);
          setLoading(false);
          Swal.fire({
            icon: "error",
            showConfirmButton: false,
            // title: a[0],
            // html: "I will close in <b></b> milliseconds.",
            // html: error.response.data.message,
            html: "Username or Email is not registered",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      });
    // setSecurity();
  };

  // =======================================================

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: {
          xs: "column-reverse",
          sm: "column-reverse",
          md: "row",
          lg: "row",
          xl: "row",
        },
        m: 0,
        p: 0,
      }}
    >
      {/* <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      > */}
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <Item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="login_register_bg">
            <div className="loginregister_block">
              <Item className="login_left">
                <Item
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                  }}
                  style={{ backgroundColor: "transparent", boxShadow: "none" }}
                >
                  <img className="company_logo" src={logo} alt="logo" />
                </Item>
                <h3>Your Data World Belongs to you!</h3>
                <p>
                  Elroi believes your data is a reflection of yourself. In order
                  to own your data, you must have data visualization. Elroi
                  solution offers real time data visualization of data you
                  provide any specific company. Watch them while they are
                  watching you!
                </p>
                <img className="lr_logo" src={loginImage} alt="logonImage" />
              </Item>
            </div>
          </div>
        </Item>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <Item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="login_register_bg">
            <div
              className="loginregister_block blue_box"
              style={{ textAlign: "center" }}
            >
              <Item
                sx={{
                  display: { xs: "block", sm: "block", md: "none", lg: "none" },
                }}
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <img className="company_logo" src={whiteLogo} alt="whiteLogo" />
              </Item>
              <Item className="login_right tfa">
                <img
                  src={forgotPass}
                  alt="forgotPass"
                  style={{
                    background: "aliceblue",
                    borderRadius: "37% 50% 40% 30% / 73% 61% 37% 61%",
                    height: "auto",
                    width: "12em",
                    padding: "2em",
                    marginBottom: "2em",
                  }}
                />

                <h2 style={{ color: "white" }}>Forgot Password</h2>
                <p>
                  You can enter your Email Address or User Name and we'll send you an OTP on your registered Email address to reset your password.
                </p>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  component="form"
                  noValidate
                  autoComplete="off"
                  className="otp_input"
                >
                  <div className="form_space">
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="userInfo">Email / Username</InputLabel>
                      <Input
                        type="text"
                        id="userInfo"
                        label="userInfo"
                        variant="standard"
                        {...register("userInfo", {
                          required: false,
                          // pattern:
                            // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                        // onChange={(e) => setEmailValue(e.target.value)}
                        onChange={(e) => emailVerify(e.target.value)}
                      />
                      {/* {errors.userInfo?.type === "required" && (
                        <Typography variant="caption" color="red">
                          Email or Username is required
                        </Typography>
                      )} */}
                      {/* {errors.userInfo?.type === "pattern" && (
                        <Typography variant="caption" color="red">
                          Email is invalid
                        </Typography>
                      )} */}
                    </FormControl>
                  </div>
                  <div className="form-button">
                    <LoadingButton
                      className="theme_btn"
                      type="submit"
                      endIcon={<LockOpenRoundedIcon />}
                      loading={loading}
                      loadingPosition="end"
                      variant="contained"
                      disabled={userInfo == ""}
                      // onBlur={(e) => emailVerify(e.target.value)}
                      onSubmit={() => handleSubmit(onSubmit)}
                    >
                      Send OTP
                    </LoadingButton>
                  </div>
                </form>
                <p style={{ marginTop: "2em" }}>
                  Don't have an account? <Link to="/register"> Register</Link>
                </p>
              </Item>
            </div>
          </div>
        </Item>
      </Grid>
      {/* </Grid> */}
    </Box>
  );
};

export default ForgotPassword;
