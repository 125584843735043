import * as React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import moment from "moment";
// import { useSelector, useDispatch } from "react-redux";
import { useDispatch } from "react-redux";

// import Footer from "./components/common/Footer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
// import { CircularProgress, Container } from "@mui/material";

import ProtectedRoute from "./ProtectedRoute";
import emailConfirm from "./components/registration/emailConfirm";
import EmailUpdate from "./components/settings/emailUpdate";
import Loader from "./components/CommonLoader";
import ForgotPassword from "./components/login/ForgotPassword";
import ResetPassword from "./components/login/ResetPassword";
// import Demo from "./components/demo/demo";

const Register = React.lazy(() => import("./components/registration/Register"));
const Login = React.lazy(() => import("./components/login/Login"));
const TwoFactAuth = React.lazy(() => import("./components/login/TwoFactAuth"));
// const Demo = React.lazy(() => import("./components/demo/demo"));
const PageNotFound = React.lazy(() => import("./components/common/PageNotFound"));
const Dashboard = React.lazy(() => import("./components/dashboard/Dashboard"));
const Settings = React.lazy(() => import("./components/settings/Settings"));
const PrivacyStatement = React.lazy(() => import("./components/privacystatement/PrivacyStatement"));
const Companies = React.lazy(() => import("./components/companies/Companies"));
const ExcelImport = React.lazy(() => import("./components/excelimport/ExcelImport"));
const YourAction = React.lazy(() => import("./components/yourAction/YourAction"));
const YourDataElements = React.lazy(() => import("./components/yourdataelement/YourDataElement"));
const CompaniesDataElement = React.lazy(() => import("./components/companiesdataelement/CompaniesDataElement"));
const CompanyDashboard = React.lazy(() => import("./components/companydashboard/CompanyDashboard"));

function App() {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  /* ---------- Theme -------------- */
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );
  /* ---------- Theme -------------- */

  const auth = () => {
    if (localStorage.getItem("user")) {
      var sessionDate = JSON.parse(
        window.atob(localStorage.getItem("user"))
      ).date;
      var diff = moment()
        .startOf("day")
        .diff(moment(sessionDate).startOf("day"), "days");
      if (diff >= 0 && diff < 1) {
        return true;
      } else {
        // sessionStorage.clear();
        localStorage.clear();
        return false;
      }
    } else {
      localStorage.clear();
      return false;
    }
  };
  const isAuthenticated = useDispatch((state) => state.user.isAuthenticated) || auth();
  // const isAuthenticated =
  //   useSelector((state) => state.user.isAuthenticated) || auth();
  /* ---------- Authentication -------------- */

  return (
    <React.Suspense
      fallback={
        <Container 
        // sx={{ width: "50px", padding: "50vh" }}
        >
          {/* <CircularProgress color="primary" size={60} /> */}
          <Loader/>
        </Container>
      }
    >
      <ThemeProvider theme={theme}>
      
        <Router>
          <Switch>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/login">
              <Login isAuthenticated={isAuthenticated} />
            </Route>
            <Route exact path="/TwoFactAuth/:userId">
              <TwoFactAuth />
            </Route>
            <Route exact path="/ForgotPassword">
              <ForgotPassword />
            </Route>
            <Route exact path="/ResetPassword/:userId">
              <ResetPassword />
            </Route>
            <Route
              exact
              path="/email-confirm/:token"
              component={emailConfirm}
            />
            <Route
              exact
              path="/email-update/:token"
              component={EmailUpdate}
            />
            <ProtectedRoute
              exact
              path="/dashboard"
              component={Dashboard}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />
            {/* <Dashboard colorMode={colorMode} theme={theme} /> */}
            <ProtectedRoute
              exact
              path="/companies"
              component={Companies}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />

            {/* <Companies colorMode={colorMode} theme={theme} /> */}
            <ProtectedRoute
              exact
              path="/privacy_statements"
              component={PrivacyStatement}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />
            {/* <PrivacyStatement colorMode={colorMode} theme={theme} /> */}
            <ProtectedRoute
              exact
              path="/your_actions"
              component={YourAction}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />
            <ProtectedRoute
              exact
              path="/your_data_elements"
              component={YourDataElements}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />

            <ProtectedRoute
              exact
              path="/excel_import"
              component={ExcelImport}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />

            <ProtectedRoute
              exact
              path="/companies_data_element/:yourDataElementId"
              component={CompaniesDataElement}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />
            <ProtectedRoute
              exact
              path="/company_dashboard/:companyId"
              component={CompanyDashboard}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />
            <ProtectedRoute
              exact
              path="/company_dashboard/:companyId/:tabValue"
              component={CompanyDashboard}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />
            <ProtectedRoute
              exact
              path="/company_dashboard"
              component={CompanyDashboard}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />
            <ProtectedRoute
              exact
              path="/settings/:tabValue"
              component={Settings}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />
            <ProtectedRoute
              exact
              path="/settings"
              component={Settings}
              isAuthenticated={isAuthenticated}
              colorMode={colorMode}
              theme={theme}
            />
            {/* <Route exact path="/demo">
              <Demo />
            </Route> */}
            {/* <Settings colorMode={colorMode} theme={theme} /> */}
            <Route exact path="/">
              <Redirect to={{ pathname: "/login" }} />
            </Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </Router>
      
        {/* <Footer /> */}
      </ThemeProvider>
    </React.Suspense>
  );
}

export default App;
