import {FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Paper, Typography} from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useForm } from "react-hook-form";
import logo from "../../assets/images/logo.png";
import resetPass from "../../assets/images/resetPass.gif";
import loginImage from "../../assets/images/loginback.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import OtpInput from "react-otp-input";
import "./Auth.scss";
import whiteLogo from "../../assets/images/mobilelogo.png";

import $ from "jquery";
// import ClearIcon from '@mui/icons-material/Clear';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import SendIcon from '@mui/icons-material/Send';

const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
}));

const body = document.querySelector("body");
body.style.setProperty("--screen-height", $(window).height() - 100 + "px");
body.style.setProperty("--login-screen-height", $(window).height() + "px");

const ResetPassword = (props) => {
  let history = useHistory();
  const { REACT_APP_API_URL } = process.env;

  /* get values from URL parameters */
  const { userId } = useParams() || "";
  // console.log(userId);
  let userIdDecode = window.atob(userId);
  // console.log(userIdDecode);
  // console.log(JSON.parse(userIdDecode).user);
  let userName = JSON.parse(userIdDecode).user;
  // console.log(userName);

  const [loading, setLoading] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  // const [userData, setUserData] = React.useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfPassword, setShowConfPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [confPassword, setConfPassword] = React.useState("");
  // const [userInfo, setUserInfo] = React.useState("");
  // const [emailError, setEmailError] = React.useState(false);

  
  const [title, setTitle] = useState("Elroi Consumer");

  useEffect(() => {
    document.title = title;
    setTitle("Elroi Consumer || Reset Password")
  }, [title]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const handleOtpChange = (otp) => {
    setOtp(otp);
    // console.log(otp);
  };

  const onSubmit = (data) => {
    setTimeout(() => {
      setLoading(true);
    }, 500);
    setInterval(() => {
      setLoading(false);
    }, 3500) 
    // debugger

    let obj = { forget_user: userName, otp: otp, password: password };
    // console.log(obj);
    axios
      .post(`${REACT_APP_API_URL}/forgot-password-verify`, obj)
      .then((result) => {
        if (result.status === 200) {
          setOtp(otp);
          // console.log(result);
          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            // title: "",
            html: "Successfully Updated Your Password",
            timer: 2000,
            timerProgressBar: true,
          });
          localStorage.setItem(
            "user",
            window.btoa(
              JSON.stringify({
                user: result.data.data,
                isAuthenticated: true,
                date: new Date(),
              })
            )
          );
          // console.log(localStorage.getItem("user"));
          setTimeout(function () {
            window.location.href = "/login";
          }, 3000);
        } else {
          // console.log(result);
          Swal.fire({
            icon: "warning",
            showConfirmButton: true,
            title: "Sorry, Verification Code is In-Correct",
            // html: "",
            timer: 5000,
            timerProgressBar: true,
          });
          setTimeout(function () {
            history.replace("/login");
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log(error);
          Swal.fire({
            icon: "error",
            showConfirmButton: false,
            // title: a[0],
            html: "Sorry, Verification Code is In-Correct",
            // html: error.response.data.message,
            timer: 2000,
            timerProgressBar: true,
          });
        }
      });
    // setSecurity();
  };

  const cancelBtn = () => {
    // setTimeout(() => {
    //   setOtp("");
    //   setPassword("");
    //   setConfPassword("");
    //   }, 1000);
    history.replace("/login");
  };
  // =======================================================

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column-reverse",
          sm: "column-reverse",
          md: "row",
          lg: "row",
          xl: "row",
        },
        m: 0,
        p: 0,
      }}
    >
      <Grid xl={6} lg={6} md={12} sm={12} xs={12}>
        <Item elevation={0} xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="login_register_bg">
            <div className="loginregister_block">
              <div className="login_left">
                <Item
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                  }}
                  style={{ backgroundColor: "transparent", boxShadow: "none" }}
                >
                  <img className="company_logo" src={logo} alt="logo" />
                </Item>
                <h3>Your Data World Belongs to you!</h3>
                <p>
                  Elroi believes your data is a reflection of yourself. In order
                  to own your data, you must have data visualization. Elroi
                  solution offers real time data visualization of data you
                  provide any specific company. Watch them while they are
                  watching you!
                </p>
                <img className="lr_logo" src={loginImage} alt="logonImage" />
              </div>
            </div>
          </div>
        </Item>
      </Grid>
      <Grid xl={6} lg={6} md={12} sm={12} xs={12}>
        <Item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="login_register_bg">
            <div
              className="loginregister_block blue_box"
              style={{ textAlign: "center" }}
            >
              <Item
                sx={{
                  display: { xs: "block", sm: "block", md: "none", lg: "none" },
                }}
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <img className="company_logo" src={whiteLogo} alt="whiteLogo" />
              </Item>
              <div className="login_right tfa">
                <img
                  src={resetPass}
                  alt="resetPass"
                  style={{
                    background: "aliceblue",
                    borderRadius: "37% 50% 56% 59% / 56% 56% 34% 53%",
                    height: "auto",
                    width: "250px",
                    marginBottom: "2em",
                  }}
                />
                <h2 style={{ color: "white" }}>Reset Password</h2>
                <p>Check your email for verification code</p>
                <p>Enter verification code and New Password.</p>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  component="form"
                  noValidate
                  autoComplete="off"
                  className="otp_input"
                >
                  <div
                    className="form_space auth"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <OtpInput
                      inputStyle="inputStyle"
                      id="verification"
                      numInputs={6}
                      isDisabled={false}
                      hasErrored={false}
                      errorStyle="error"
                      onChange={handleOtpChange}
                      separator={<span> </span>}
                      isInputNum={true}
                      isInputSecure={false}
                      focusStyle={false}
                      shouldAutoFocus={false}
                      value={otp}
                    />
                  </div>
                  <div className="form_space">
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <Input
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              className="icn_btn"
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        id="password"
                        variant="standard"
                        {...register("Password", {
                          required: true,
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/,
                        })}
                        onBlur={(e) => setPassword(e.target.value)}
                      />
                      {errors.Password?.type === "required" && (
                        <Typography variant="caption" color="red">
                          Password is required
                        </Typography>
                      )}
                      {errors.Password?.type === "pattern" && (
                        <Typography variant="caption" color="red">
                          Minimum length 12 characters. At least one upper case, special character and number required.
                        </Typography>
                      )}
                    </FormControl>
                  </div>
                  <div className="form_space">
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="confPassword">
                        Confirm Password
                      </InputLabel>
                      <Input
                        type={showConfPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              className="icn_btn"
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowConfPassword(!showConfPassword)
                              }
                            >
                              {showConfPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        id="confPassword"
                        variant="standard"
                        {...register("ConfPassword", {
                          required: true,
                        })}
                        onChange={(e) => setConfPassword(e.target.value)}
                      />
                      {/* {errors.ConfPassword?.type === "required" && (
                          <Typography variant="caption" color="red">
                            Confirm Password is required
                          </Typography>
                        )} */}
                      {password !== confPassword && (
                        <Typography variant="caption" color="red">
                          Confirm Password must be same
                        </Typography>
                      )}
                    </FormControl>
                  </div>
                  <div className="form-button tfa_button">
                    <LoadingButton
                      className="theme_btn"
                      type="submit"
                      endIcon={<LockResetIcon />}
                      loading={loading}
                      loadingPosition="end"
                      variant="contained"
                      disabled={otp.length < 6}
                      style={{
                        marginRight: "1em",
                      }}
                    >
                      Reset Password
                    </LoadingButton>
                    <LoadingButton
                      className="themeoption_btn"
                      type="cancel"
                      endIcon={<RestartAltIcon />}
                      loading={false}
                      loadingPosition="end"
                      variant="contained"
                      onClick={() => cancelBtn()}
                      style={{
                        marginRight: "1em",
                      }}
                    >
                      Cancel
                    </LoadingButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Item>
      </Grid>
      {/* </Grid> */}
    </Box>
  );
};

export default ResetPassword;
