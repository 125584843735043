import loader from "../assets/images/loader.png";

import $ from "jquery";
const body = document.querySelector("body");
body.style.setProperty("--screen-height", $(window).height() - 100 + "px");
body.style.setProperty("--login-screen-height", $(window).height() + "px");
export default function CommonLoader(props) {
    return (
        <div className="loader_block common_page">
            <div className="height_loader">
              <div className="img_loader">
                <img src={loader} alt="logo" />
                <div className="loader">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
    )
}